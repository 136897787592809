import React from "react";

function Lethargy() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="90"
            height="90"
            fill="none"
            viewBox="0 0 90 90"
        >
            <circle cx="45" cy="45" r="45" fill="#674998"></circle>
            <path
                fill="#fff"
                d="M38.104 39.664a2.386 2.386 0 00-2.367 2.367 2.386 2.386 0 002.367 2.367 2.386 2.386 0 002.366-2.367 2.386 2.386 0 00-2.366-2.367zM51.816 39.664a2.386 2.386 0 00-2.366 2.367 2.386 2.386 0 002.366 2.367 2.386 2.386 0 002.367-2.367 2.386 2.386 0 00-2.367-2.367z"
            ></path>
            <path
                fill="#fff"
                d="M44.96 26C32.846 26 23 35.846 23 47.96s9.846 21.96 21.96 21.96 21.96-9.846 21.96-21.96S57.074 26 44.96 26zm0 2.664c10.672 0 19.296 8.624 19.296 19.296 0 10.672-8.624 19.296-19.296 19.296-10.672 0-19.296-8.624-19.296-19.296 0-10.671 8.624-19.296 19.296-19.296z"
            ></path>
            <path
                fill="#fff"
                d="M44.96 51.237c-4.046.008-8.109 1.855-10.507 5.581-.469.729-.389 1.657.275 2.035.703.401 1.504.116 1.936-.548 1.871-2.873 5.085-4.304 8.296-4.313 3.212-.007 6.426 1.424 8.296 4.298.433.664 1.234.964 1.937.563.664-.378.744-1.322.275-2.05-2.399-3.727-6.461-5.573-10.507-5.565h-.001z"
            ></path>
        </svg>
    );
}

export default Lethargy;

import React from "react";

function RefusingToEat() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="90"
            height="90"
            fill="none"
            viewBox="0 0 90 90"
        >
            <circle cx="45" cy="45" r="45" fill="#674998"></circle>
            <path
                fill="#fff"
                d="M54.376 65.833H34.584a1.042 1.042 0 010-2.083h19.792a1.042 1.042 0 010 2.083z"
            ></path>
            <path
                fill="#fff"
                d="M54.376 70H34.584a1.042 1.042 0 01-1.041-1.042v-7.291h-2.344a4.954 4.954 0 01-4.948-4.948V51.25h-5.208a1.042 1.042 0 01-.952-1.465l4.077-9.172V38.75c0-10.255 8.233-18.619 18.508-18.748 2.502-.034 4.95.442 7.28 1.409a1.042 1.042 0 11-.8 1.924 16.206 16.206 0 00-6.454-1.25c-9.071.114-16.451 7.59-16.451 16.665v2.084c0 .145-.03.29-.09.422l-3.515 7.91h4.647c.575 0 1.041.467 1.041 1.043v6.51a2.868 2.868 0 002.865 2.864h3.385c.575 0 1.042.467 1.042 1.042v7.292h17.709c.084-.902-.671-9.538 3.972-20.5a1.042 1.042 0 011.919.814c-4.621 10.906-3.68 19.264-3.808 20.727 0 .575-.466 1.042-1.042 1.042z"
            ></path>
            <path
                fill="#fff"
                d="M59.838 42.99c-.267 0-.534-.101-.737-.305l-3.683-3.682-3.682 3.682a1.042 1.042 0 01-1.473 0l-2.947-2.946a1.042 1.042 0 010-1.473l3.683-3.683-3.683-3.682a1.041 1.041 0 010-1.474l2.947-2.946a1.042 1.042 0 011.473 0l3.682 3.682 3.683-3.682a1.042 1.042 0 011.473 0l2.947 2.946a1.042 1.042 0 010 1.474l-3.683 3.682 3.682 3.683a1.041 1.041 0 010 1.473l-2.946 2.947c-.203.203-.47.305-.736.305zm-4.42-6.502c.267 0 .533.102.737.305l3.682 3.683 1.473-1.474-3.682-3.682a1.041 1.041 0 010-1.473l3.682-3.683-1.473-1.473-3.682 3.682a1.042 1.042 0 01-1.474 0L51 28.691l-1.474 1.473 3.683 3.683a1.042 1.042 0 010 1.473l-3.682 3.682 1.473 1.474 3.682-3.683c.204-.203.47-.305.737-.305z"
            ></path>
            <path
                fill="#fff"
                d="M55.418 49.167c-8.042 0-14.583-6.542-14.583-14.583 0-8.04 6.542-14.583 14.583-14.583 8.04 0 14.583 6.542 14.583 14.583 0 8.04-6.543 14.583-14.583 14.583zm0-27.084c-6.893 0-12.5 5.608-12.5 12.5s5.607 12.5 12.5 12.5c6.892 0 12.5-5.608 12.5-12.5s-5.608-12.5-12.5-12.5z"
            ></path>
        </svg>
    );
}

export default RefusingToEat;

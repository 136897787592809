import React from "react";

function Diarrhea() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="90"
            height="90"
            fill="none"
            viewBox="0 0 90 90"
        >
            <circle cx="45" cy="45" r="45" fill="#674998"></circle>
            <path
                fill="#fff"
                d="M67.449 25.554a7.516 7.516 0 00-6.596-1.278l-4.682 1.295a41.264 41.264 0 01-21.916 0l-4.685-1.295A7.563 7.563 0 0020 31.57v21.924a7.05 7.05 0 0012.288 4.734 11.93 11.93 0 007.598 2.488 15.663 15.663 0 002.812-.26v5.152c0 .088.013.175.041.258l2.125 6.506A2.51 2.51 0 0047.222 74h3.547a2.53 2.53 0 002.374-1.667l1.896-5.888h4.465a10.932 10.932 0 0010.92-10.925V31.57a7.506 7.506 0 00-2.975-6.016zm-24.533 33.16c-3 .668-6.903.482-9.679-1.852a6.848 6.848 0 00.75-2.1c2.307.666 5.011 1.005 7.374.009a.834.834 0 00-.638-1.54c-2.155.892-4.6.432-6.61-.167v-9.166c3.992.891 8.177 1.37 12.185.062a.834.834 0 10-.513-1.583c-3.833 1.242-7.858.682-11.672-.183v-1.276a2.542 2.542 0 013.208-2.434 23.096 23.096 0 0013.5.624 14.56 14.56 0 01-.584 4.688.834.834 0 001.617.408c.536-1.796.74-3.674.604-5.543l.645-.177a2.542 2.542 0 013.205 2.434v12.366a2.523 2.523 0 01-1.068 2.057 26.613 26.613 0 00-.396-2.774 4.432 4.432 0 00-2.166-2.991C48.49 47.4 43.42 48.308 40.23 48.57a.845.845 0 00-.852.813.834.834 0 00.813.853c3.074-.198 7.891-1.16 11.68.799a2.765 2.765 0 011.34 1.869c.188.961.32 1.932.397 2.91h-4.78a9.044 9.044 0 00-.315-2.04.834.834 0 00-1.603.453c.133.519.21 1.049.231 1.584a4.2 4.2 0 00-4.225 2.904zm25.842-3.194a9.265 9.265 0 01-9.255 9.255H55.04a1.673 1.673 0 00-1.575 1.124l-1.892 5.876a.858.858 0 01-.804.558h-3.547a.847.847 0 01-.788-.518l-2.07-6.34V60.01a2.536 2.536 0 012.53-2.53h6.885a4.238 4.238 0 004.196-4.195V40.918a4.218 4.218 0 00-5.315-4.04 22.445 22.445 0 01-14.891 0 4.218 4.218 0 00-5.319 4.04v12.576a5.461 5.461 0 01-5.388 5.396 5.457 5.457 0 01-5.395-5.396V31.57a5.93 5.93 0 017.463-5.688l4.683 1.295a42.928 42.928 0 0022.805 0l4.678-1.294a5.93 5.93 0 017.462 5.687l.001 23.95z"
            ></path>
            <path
                fill="#fff"
                d="M67.062 46.613a3.695 3.695 0 10-7.391 0v6.667a5.854 5.854 0 01-.99 3.265 3.727 3.727 0 00.147 4.289 3.817 3.817 0 002.917 1.516 3.652 3.652 0 001.35-.27c2.259-.9 4.114-3.986 3.97-6.556l-.003-8.91zM62.48 60.53a1.938 1.938 0 01-2.338-.727 2.038 2.038 0 01-.07-2.325 7.51 7.51 0 001.27-4.191v-6.667a2.029 2.029 0 014.059 0v8.946c.1 1.875-1.347 4.333-2.925 4.962l.004.002zM27.297 28.168a2.918 2.918 0 00-2.063 4.979 2.918 2.918 0 004.125-4.125 2.918 2.918 0 00-2.062-.854zm0 4.166a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM27.297 40.668a2.918 2.918 0 100 5.835 2.918 2.918 0 000-5.835zm0 4.166a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM48.963 59.418a2.918 2.918 0 00-2.917 2.916 2.918 2.918 0 004.98 2.063 2.917 2.917 0 00-2.063-4.98zm0 4.166a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM28.129 37.334a.833.833 0 11-1.667 0 .833.833 0 011.667 0zM35.213 32.334a.833.833 0 11-1.667 0 .833.833 0 011.667 0zM56.045 61.5a.833.833 0 11-1.666 0 .833.833 0 011.666 0zM49.795 68.167a.833.833 0 11-1.667 0 .833.833 0 011.667 0zM28.129 49.834a.833.833 0 11-1.667 0 .833.833 0 011.667 0zM65.795 35.032l-.028-4.97a2.39 2.39 0 00-2.98-2.286c-7.258 1.916-14.369 3.591-21.571 3.019a2.378 2.378 0 00-.514 4.723c4.397.66 10.737-.41 11.043-.464.07-.015 6.25-1.36 8.94-1.868l.06 1.845h-.137a1.43 1.43 0 00-1.1 2.324l2.68 3.266a1.431 1.431 0 002.198 0l2.68-3.267a1.432 1.432 0 00-1.1-2.322h-.171zm-2.509 4.303l-2.158-2.637h.48a.84.84 0 00.833-.86l-.12-3.667a.835.835 0 00-.954-.798c-1.736.256-9.639 1.976-9.938 2.042-.062.011-6.345 1.07-10.476.452a.712.712 0 01.138-1.414c7.454.6 14.72-1.108 22.121-3.066a.715.715 0 01.89.684l.032 5.798a.832.832 0 00.833.833h.48l-2.16 2.633z"
            ></path>
        </svg>
    );
}

export default Diarrhea;

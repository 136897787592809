import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

import AbdominalPain from "./icons/AbdominalPain";
import Lethargy from "./icons/Lethargy";
import Diarrhea from "./icons/Diarrhea";
import Swelling from "./icons/Swelling";
import RefusingToEat from "./icons/RefusingToEat";
import HeartRate from "./icons/HeartRate";

const sideEffectList = [
    {
        title: "Abdominal Pain",
        icon: <AbdominalPain />,
    },
    {
        title: "Diarrhea with Bloody Stool",
        icon: <Diarrhea />,
    },
    {
        title: "Changes in Heart Rate",
        icon: <HeartRate />,
    },
    {
        title: "Swelling",
        icon: <Swelling />,
    },
    {
        title: "Lethargy",
        icon: <Lethargy />,
    },

    {
        title: "Refusing to Eat",
        icon: <RefusingToEat />,
    },
];

export default function SideEffects(): ReactElement {
    return (
        <div className={`${styles.wrapper}`}>
            <div className="mx-auto lg:max-w-7xl flex justify-center items-start flex-col px-8">
                <h2>What Are the Symptoms of NEC?</h2>
                <p>
                    Necrotizing Enterocolitis may occur within two to six weeks
                    after birth. Symptoms may include:
                </p>

                <ul className="flex flex-wrap justify-center mt-12 gap-16">
                    {sideEffectList.map((benefit, index) => (
                        <li
                            key={index}
                            className="flex flex-col items-center justify-center w-52 md:w-64 mx-10 text-center"
                        >
                            {benefit.icon}
                            <span className="mt-2">{benefit.title}</span>
                        </li>
                    ))}
                </ul>
                <p className="mt-10">
                    NEC can also lead to cerebral palsy or death in severe
                    cases. If you believe your child may have NEC, consult with
                    your healthcare provider immediately. They may take blood
                    and fecal tests and perform x-rays to determine the
                    diagnosis of your newborn.
                </p>
            </div>
        </div>
    );
}
